import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import {Container,Row,Col,Toast,Button,Form,Label,Control} from 'react-bootstrap';
import Axios from 'axios';
import Qs from 'querystring';
import { FacebookProvider, LoginButton } from 'react-facebook';
import { GoogleLogin } from 'react-google-login';
import Cookies from 'universal-cookie';

import './earthly.css';
import earthly_logo from "../images/earthly_logo.png";
import message_icon from '../images/email.svg'
import earthly_sign_up from '../images/signup_img.png'
import earthly_bottom_signup from '../images/bottom_signup.png'
import hero_img from '../images/earthly_banner.png';
import {API_URL,CONFIG_WITHOUT_TOKEN,FACEBOOK_ID,GOOGLE_LOGIN_ID} from '../appconstant/index';
const cookies = new Cookies();

const Uninstall = () => {
    const [showtext, setShowtext] = useState(false);
    const [btndisable, setBtndisable] = useState(false);
    const [selectedreason, setSelectedreason] = useState([]);
    const [otherreason, setOtherreason] = useState(false);
    const [webtoken, setWebtoken] = useState(null);
    const [thankyou, setThankyou] = useState(false);
    const selected_val = (e) =>{
        setBtndisable(true);
        if(e.target.checked == true){
            selectedreason.push(e.target.value);
            setSelectedreason(selectedreason);
            if(e.target.value == "other"){
                setOtherreason(e.target.value);
                setShowtext(true);
            }
        }else if(e.target.checked == false){
            if(e.target.value == "other"){
                setOtherreason("");
                setShowtext(false);
            }
            let index = selectedreason.indexOf(e.target.value);
            if (index > -1) {
                selectedreason.splice(index, 1);
                setSelectedreason(selectedreason);
            }
        }
        if(selectedreason.length == 0){
            setBtndisable(false);
        }
    }
    useEffect(() => {
        let web_token =  localStorage.getItem('earthly_web_token');
        setWebtoken(web_token);
        const CONFIG_WITH_TOKEN_CALL = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization' :'Bearer '+ web_token
          }
        }
        let uninstall_data ={
            noreason:"yes"
        }
        Axios.post(API_URL + '/apis/user/uninstall', Qs.stringify(uninstall_data),CONFIG_WITH_TOKEN_CALL)
                  .then((response) => {
                        if(response.data.error == 0){
                        }else if(response.data.error == 1){
                            localStorage.removeItem("earthly_web_token");
                            localStorage.removeItem("earthly_login_user");
                            cookies.set('earthly_token',"" , { path: '/'});

                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

    },[])

const handleRegisterSubmit = (event) =>{
        event.preventDefault();
        console.log(selectedreason);
        if(selectedreason !== "" && selectedreason.length > 0 ){
            if(selectedreason.includes("other") == true){
                let other_idnex = selectedreason.indexOf("other");
                    selectedreason.splice(other_idnex,1);
                    selectedreason.push(otherreason);

            }
            let uninstall_data ={
                reason: JSON.stringify(selectedreason)
            }
            const CONFIG_WITH_TOKEN = {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization' :'Bearer '+ webtoken
              }
            }
            Axios.post(API_URL + '/apis/user/uninstall', Qs.stringify(uninstall_data),CONFIG_WITH_TOKEN)
                      .then((response) => {
                            if(response.data.error == 0){
                                setThankyou(true);
                                localStorage.removeItem("earthly_web_token");
                                localStorage.removeItem("earthly_login_user");
                                cookies.set('earthly_token',"" , { path: '/'});
                            }else if(response.data.error == 1){
                                console.log(response.data.error.message);
                            }
                      })
                      .catch((error) => {
                            console.log(error.message);
                      });
        }




}
const other_reason_text = (e) => {
    setOtherreason(e.target.value);
}
  return (
    <div>
      <SEO title="Login" image={hero_img} />
      <Container fluid className="p-0" style={{overflow:"hidden"}}>
      <Row>
        <Col>
          <div className="col-12 p-0">
             <div className="min-vh-100 m-0 row">
                <div className="col-lg-6 d-flex align-items-center login_img_section signup_image" style={{paddingLeft:"0px"}}>
                  <img src={earthly_sign_up} alt="signup image" />
                </div>
                <div className="col-lg-6 col-sm-12 d-flex align-items-center">
                    {thankyou !== true &&
                    <div className="uninstall_content">
                    <div style={{marginBottom:"31px"}}>
                        <img src={earthly_logo} alt="Earthly Login" style={{height:"50px",marginBottom:"15px"}}/>
                    </div>
                    <div className="uninstall_header_section">
                        <h2 className="uninstall_header_text">Please help us understand why you decided to uninstall Earthly</h2>
                    </div>
                    <div><hr/></div>
                    <div>
                    <Form onSubmit={handleRegisterSubmit}>
                        <div>
                            <div className="uninstall_radio_label">
                                <input className="uninstall_radio_button" type="checkbox" name="uninstallform" value="I find the plugin distracting when I shop" id="distracting_plugin" onChange={selected_val} />
                                <Form.Label htmlFor="distracting_plugin" style={{paddingLeft:"10px"}}>I find the plugin distracting when I shop</Form.Label>
                            </div>
                            <div className="uninstall_radio_label">
                                <input className="uninstall_radio_button" type="checkbox" name="uninstallform" value="I don't trust the data displayed by the plugin" id="donttrustdata" onChange={selected_val} />
                                <Form.Label htmlFor="donttrustdata" style={{paddingLeft:"10px"}}>I don't trust the data displayed by the plugin</Form.Label>
                            </div>
                            <div className="uninstall_radio_label">
                                <input className="uninstall_radio_button" type="checkbox" name="uninstallform" value="I'm concerned about privacy" id="privacy" onChange={selected_val} />
                                <Form.Label htmlFor="privacy" style={{paddingLeft:"10px"}}>I'm concerned about privacy</Form.Label>
                            </div>
                            <div className="uninstall_radio_label">
                                <input className="uninstall_radio_button" type="checkbox" name="uninstallform" value="It's missing a feature I need" id="missing_feature" onChange={selected_val} />
                                <Form.Label htmlFor="missing_feature" style={{paddingLeft:"10px"}}>It's missing a feature I need</Form.Label>
                            </div>
                            <div className="uninstall_radio_label">
                                <input className="uninstall_radio_button" type="checkbox" name="uninstallform" value="There aren’t enough labels for this to be useful at this time" id="missing_label" onChange={selected_val} />
                                <Form.Label htmlFor="missing_label" style={{paddingLeft:"10px"}}>There aren’t enough labels for this to be useful at this time</Form.Label>
                            </div>

                            <div className="uninstall_radio_label">
                                <input className="uninstall_radio_button" type="checkbox" name="uninstallform" value="other" id="other" onChange={selected_val} />
                                <Form.Label htmlFor="other" style={{paddingLeft:"10px"}}>Other:</Form.Label>
                            </div>
                            {showtext == true &&
                            <div style={{marginTop:"24px"}}>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                               <Form.Control as="textarea" rows={3} onChange={other_reason_text}  className="uninstall_other_text_reason"  name="other_reason" placeholder="Type here" />
                             </Form.Group>
                            </div>
                            }
                        </div>
                        <div style={{textAlign:"center"}} className="uninstall_radio_label">
                            <Button variant="primary" disabled={btndisable == false ? true:false} className="btn btn-lg btn-block submit_login_btn" type="submit">
                              Submit
                            </Button>
                        </div>
                    </Form>
                    </div>
                    </div>
                    }
                    {thankyou == true &&
                    <div className="uninstall_content">
                        <h2>
                            Thank you, hope to see you soon
                        </h2>
                    </div>
                    }
                </div>

                <div className="row login_img_section">
                   <img src={earthly_bottom_signup} alt="bottom_signup" className="bottom_signup_img"/>
                </div>
             </div>
          </div>
        </Col>
      </Row>
      </Container>

    </div>



  )



}

export default Uninstall;
